





















































































































import { debounceProcess } from "@/helpers/debounce";
import { ResponsePagination } from "@/models/constant/interface/common.interface";
import { Messages } from "@/models/enums/messages.enum";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DataResponseBastExchange } from "@/models/interface/salesOrder.interface";
import { logisticServices } from "@/services/logistic.service";
import { salesOrderServices } from "@/services/salesorder.service";
import moment from "moment";
import Vue from "vue";

interface LoadingComponent {
  loadingFind: boolean;
  loadingInternalContractNumber: boolean;
  download: boolean;
  print: boolean;
  loadingCustomer: boolean;
}

export default Vue.extend({
  name: "TarikUnit",
  data() {
    this.getWoNumber = debounceProcess(this.getWoNumber, 200);
    this.getInternalContractNumber = debounceProcess(
      this.getInternalContractNumber,
      200
    );
    this.getCustomerName = debounceProcess(this.getCustomerName, 200);
    return {
      form: this.$form.createForm(this, { name: "tarikUnit" }),
      page: this.page || (1 as number),
      limit: this.limit || (10 as number),
      params: {} as RequestQueryParamsModel,
      totalData: 0 as number,
      customerId: "" as string,
      loading: {
        loadingFind: false,
        loadingInternalContractNumber: false,
        download: false,
        print: false,
        loadingCustomer: false,
      } as LoadingComponent,
      columnsTable: [
        {
          title: "No",
          dataIndex: "no",
          key: "no",
          width: 150,
          scopedSlots: { customRender: "no" },
        },
        {
          title: "Internal Contract Number",
          dataIndex: "icNumber",
          key: "icNumber",
          width: 150,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_customer_name"),
          dataIndex: "customerName",
          key: "customerName",
          width: 150,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: "Rent Period",
          dataIndex: "rangeRentPeriod",
          key: "rangeRentPeriod",
          width: 150,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: "Qty",
          dataIndex: "qty",
          key: "qty",
          width: 150,
          scopedSlots: { customRender: "isQty" },
        },
        {
          title: this.$root.$t("lbl_action").toString(),
          dataIndex: "operation",
          key: "operation",
          scopedSlots: { customRender: "operation" },
          button: ["view"],
          width: 120,
          align: "center",
        },
      ],
      formRules: {
        internalContractNumber: {
          label: "Internal Contract Number",
          name: "internalContractNumber",
          placeholder: "Select Internal Contract Number",
          decorator: [
            "internalContractNumber",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        customerName: {
          label: "lbl_customer_name",
          name: "customerName",
          placeholder: "lbl_customer_name_placeholder",
          decorator: [
            "customerName",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
      },
      dataSource: [] as DataResponseBastExchange[],
      dataInternalContractNumber: [] as DataResponseBastExchange[],
      dataCustomerName: [] as DataResponseBastExchange[],
    };
  },
  methods: {
    handleDownload() {
      this.form.validateFields((err, res) => {
        if (err) return;
        this.loading.download = true;
        this.params.params = this.checkParams(res);
        logisticServices
          .getDownloadResultdWorkOrder(this.params)
          .then(response => {
            if (response) {
              const url = window.URL.createObjectURL(new Blob([response]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", "report_customer_maintenance.xlsx"); //or any other extension
              document.body.appendChild(link);
              link.click();
            }
          })
          .finally(() => (this.loading.download = false));
      });
    },
    responseViewTable(response) {
      this.$router.push(`/sales/tukar-unit/cetak/${response.data.id}`);
    },
    getInternalContractNumber(valueSearch) {
      const params: RequestQueryParamsModel = {
        limit: 10,
        page: 0,
        search: `state~APPROVED`,
      };
      if (valueSearch)
        params.search += `_AND_internalContractDetail.internalContract.documentNo~*${valueSearch}*`;
      if (this.customerId && params.search)
        params.search += `_AND_internalContractDetail.internalContract.customer.secureId~${this.customerId}`;

      this.loading.loadingInternalContractNumber = true;
      salesOrderServices
        .getListBastExchange(params)
        .then(response => {
          this.dataInternalContractNumber = response.data
            .map((dataMap, index) => {
              return { ...dataMap, key: index, no: index + 1 };
            })
            .filter(
              (value, index, self) =>
                index === self.findIndex(t => t.icNumber === value.icNumber)
            );
        })
        .finally(() => (this.loading.loadingInternalContractNumber = false));
      // salesOrderServices
      //   .getListInternalContract(params)
      //   .then((response) => this.dataInternalContractNumber = response.data)
      //   .finally(() => this.loading.loadingInternalContractNumber = false)
    },
    handleChangeCustomer(value) {
      this.form.resetFields(["internalContractNumber"]);
      this.customerId = value;
      this.getInternalContractNumber("");
    },
    getCustomerName(valueSearch) {
      const params: RequestQueryParamsModel = {
        limit: 10,
        page: 0,
        search: "state~APPROVED",
      };
      if (valueSearch)
        params.search += `_AND_internalContractDetail.internalContract.customer.firstName~*${valueSearch}*_OR_internalContractDetail.internalContract.customer.lastName~*${valueSearch}*`;
      this.loading.loadingCustomer = true;
      salesOrderServices
        .getListBastExchange(params)
        .then(response => {
          this.dataCustomerName = response.data
            .map((dataMap, index) => {
              return { ...dataMap, key: index, no: index + 1 };
            })
            .filter(
              (value, index, self) =>
                index ===
                self.findIndex(t => t.customerName === value.customerName)
            );
        })
        .finally(() => (this.loading.loadingCustomer = false));
      // contactServices
      //   .listContactData(params)
      //   .then(response => this.dataCustomerName = response.data)
      //   .finally(() => this.loading.loadingCustomer = false)
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.limit = response.size;
      this.page = 1;
      this.onSubmit(false);
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.page = response.page;
      this.onSubmit(false);
    },
    checkValue(value): string {
      if (value && !value.includes("undefined")) {
        return value;
      } else {
        return ",ALL";
      }
    },
    checkParams(res): string {
      let params = "";
      // company is mandatory
      params += this.$store.state.authStore.authData.companyName;
      params += this.checkValue(`,${res["customerMaintenanceNumber"]}`);
      params += this.checkValue(`,${res["workOrderNumber"]}`);
      params += this.checkValue(`,${res["csfNumber"]}`);
      params += this.checkValue(`,${res["customerName"]}`);
      params += this.checkValue(`,${res["unitCode"]}`);
      params += this.checkValue(
        res["dateFrom"]
          ? `,${moment(res["dateFrom"]).format("DD-MMM-yyyy")}`
          : null
      );
      params += this.checkValue(
        res["dateTo"] ? `,${moment(res["dateTo"]).format("DD-MMM-yyyy")}` : null
      );

      return params;
    },
    assignSearch(key, value, and): string {
      if (key === "customerName" && value)
        return (
          and +
          `internalContractDetail.internalContract.customer.secureId~${value}`
        );
      else if (key === "internalContractNumber" && value)
        return (
          and + `internalContractDetail.internalContract.documentNo~${value}`
        );
      else return "";
    },
    dynamicSearch(res): string {
      let search = "";
      Object.keys(res).forEach(key => {
        if (!search) {
          search = this.assignSearch(key, res[key], "");
        } else {
          search += this.assignSearch(key, res[key], "_AND_");
        }
      });
      return search;
    },
    onSubmit(firstSearch): void {
      this.form.validateFields((err, values) => {
        if (err) return;
        const params: RequestQueryParamsModel = {
          page: this.page - 1,
          limit: this.limit,
        };
        params.search = this.dynamicSearch(values);
        if (params.search) {
          params.search += "_AND_state~APPROVED";
        } else {
          params.search = "state~APPROVED";
        }
        if (firstSearch) {
          params.page = 0;
          this.page = 1;
        }
        this.loading.loadingFind = true;
        salesOrderServices
          .getListBastExchange(params)
          .then(response => {
            this.totalData = response.totalElements;
            this.dataSource = response.data.map((dataMap, index) => {
              return {
                ...dataMap,
                key: index,
                no:
                  response.currentPage === 0
                    ? index + 1
                    : this.limit * (this.page - 1) + index + 1,
              };
            });
          })
          .finally(() => (this.loading.loadingFind = false));
      });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].componentOptions.children[1].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
  created() {
    this.getCustomerName("");
    this.getInternalContractNumber("");
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 },
      };
    },
  },
});
